@import "./_variables.pcss";

$orange_main: #ff5500;

.hidden {
    display: none !important;
}

.orange {
    color: $orange_main;
}

.list__ {
    a {
        display: block;

        line-height: 46px;
        padding: 0 12px;
        border: 1px solid transparent;
        box-sizing: border-box;
    }

    a:nth-child(2n + 1) {
        background-color: #f5f5f5;
    }

    a:hover {
        border-color: $orange_main;
    }
}

/* --- Bug Tracker --- */

.scan__block {
    position: fixed;
    top: calc(100% - 200px);
    right: 12px;
    height: 120px;
    width: 120px;
    background-color: #5d5b5b;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    line-height: 30px;
    font-family: sans-serif;
    font-size: 20px;
    /*overflow: hidden;*/
    white-space: nowrap;
    z-index: 9999999;

    &:hover {
        /*width: 140px;*/
        /*overflow-y: auto;*/
        background-color: #be2a2a;
        color: #fff;
        cursor: pointer;
    }
}

.report__button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* --- FLEX --- */

.flex-end {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}

.flex-btw {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-align-items: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.flex-around {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
}

.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-align-items: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.flex-center {
    display: flex;
    -ms-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.flex-start {
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
}

.m6 {
    margin: 6px;
}
.mt6 {
    margin-top: 6px;
}
.ml6 {
    margin-left: 6px;
}
.mr6 {
    margin-right: 6px;
}
.mb6 {
    margin-bottom: 6px;
}
.mtb6 {
    margin-top: 6px;
    margin-bottom: 6px;
}
.mlr6 {
    margin-left: 6px;
    margin-right: 6px;
}

.m10 {
    margin: 10px;
}
.mt10 {
    margin-top: 10px;
}
.ml10 {
    margin-left: 10px;
}
.mr10 {
    margin-right: 10px;
}
.mb10 {
    margin-bottom: 10px;
}
.mtb10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.mlr10 {
    margin-left: 10px;
    margin-right: 10px;
}

.m12 {
    margin: 12px;
}
.mt12 {
    margin-top: 12px;
}
.ml12 {
    margin-left: 12px;
}
.mr12 {
    margin-right: 12px;
}
.mb12 {
    margin-bottom: 12px;
}
.mtb12 {
    margin-top: 12px;
    margin-bottom: 12px;
}
.mlr12 {
    margin-left: 12px;
    margin-right: 12px;
}

.m16 {
    margin: 16px;
}
.mt16 {
    margin-top: 16px;
}
.ml16 {
    margin-left: 16px;
}
.mr16 {
    margin-right: 16px;
}
.mb16 {
    margin-bottom: 16px;
}
.mtb16 {
    margin-top: 16px;
    margin-bottom: 16px;
}
.mlr16 {
    margin-left: 16px;
    margin-right: 16px;
}

.m20 {
    margin: 20px;
}
.mt20 {
    margin-top: 20px;
}
.ml20 {
    margin-left: 20px;
}
.mr20 {
    margin-right: 20px;
}
.mb20 {
    margin-bottom: 20px;
}
.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.mlr20 {
    margin-left: 20px;
    margin-right: 20px;
}

.p6 {
    padding: 6px;
}
.ptb6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.p12 {
    padding: 12px;
}
.pt12 {
    padding-top: 12px;
}
.pl12 {
    padding-left: 12px;
}
.pr12 {
    padding-right: 12px;
}
.pb12 {
    padding-bottom: 12px;
}
.ptb12 {
    padding-top: 12px;
    padding-bottom: 12px;
}
.plr12 {
    padding-left: 12px;
    padding-right: 12px;
}

.lh44 {
    line-height: 44px;
}

/* --- OTHER --- */

.w100p {
    width: 100%;
}

.center {
    text-align: center;
}

.block {
    display: block !important;
}

.none {
    display: none !important;
}

.pointer {
    cursor: pointer;
}

.clear {
    clear: both;
}

.nos {
    -moz-user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.img__loading {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 16px);
}

.btn__ {
    position: relative;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    min-width: 30px;
    width: 30px;
    padding: 0 4px;
    text-align: center;
    border-radius: 7px;

    color: #515365;

    &:before {
        display: inline-block;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        color: #515365;
    }

    .img__loading {
        position: relative;
        top: inherit;
        left: inherit;
        width: 20px;
        height: 20px;
        margin-left: 5px;
    }

    &:hover {
        cursor: pointer;
        background-color: $orange_main;
        color: white !important;

        &:before {
            color: white !important;
        }
    }
}

.btn__circle {
    &:before {
        display: inline-block;
        width: 34px;
        line-height: 34px;
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        color: #cdcdcd;
        background-color: $color_white;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover {
        &:before {
            color: $color_white;
            background-color: #506690;
        }
    }
}

.pop_form {
    .pf_row {
        margin-bottom: 12px;

        label {
            display: block;
            font-size: 14px;
            margin-bottom: 5px;
            color: #666;
            font-weight: lighter;
            align-items: center;
        }

        input[type="tel"],
        input[type="password"],
        input[type="text"],
        input[type="number"] {
            width: 100%;
            height: 36px;
            line-height: 36px;
            background: #f9f9f9;
            border: none;
            padding: 0 15px;
            font-size: 15px;
            box-sizing: border-box;
            font-weight: lighter;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
        }
    }
}

/*---------------------------- Sections ----------------------------*/

.heading-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hf-title {
    color: #010b15;
    font-size: 1.5rem;
    font-weight: 500;

    span {
        font-size: 14px;
        font-weight: 600;
        color: #8d8f8e;
    }
}

.section-row-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;

    .sr-icon-move {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 20px;
        margin-top: 9px;
        background: #ffa012;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: $color_white;
        line-height: 1;
    }
}

.section-row {
    width: 100%;
    min-width: 10px;
    max-width: 100%;

    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all 300ms linear;

    &:hover {
        border-color: #2662d6;
    }

    &.active {
        border-color: #2662d6;

        .sr-head {
            .sr-arrow {
                transform: rotate(180deg) translate(0, 60%);
            }
        }
    }

    .sr-head {
        background: #fafafa;
        padding: 0 45px 0 12px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        color: #434343;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        .sr-icon {
            margin-right: 15px;
            width: 50px;

            img {
                display: block;
                max-width: 100%;
            }
        }

        .sr-arrow {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 18px;
            font-size: 20px;
            line-height: 1;
            color: #434343;
            transition: all 300ms linear;
        }
    }

    .sr-cont {
        display: none;
        padding: 15px 12px 10px 12px;
    }
}

/*---------------------------- /Sections ----------------------------*/

.radio-pretty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
    max-width: 500px;

    label {
        display: block;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin: 0;

        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }

        input:checked + .radio-pretty-title {
            border-color: $orange_main;
        }

        .radio-pretty-title {
            padding: 6px 12px;
            margin: 0;
            border: 2px solid #ebebeb;
            border-radius: 6px;
            cursor: pointer;
            transition: all 300ms linear;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                display: block;
                margin-right: 10px;
            }

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }
}

/*---------------------------- Reset Article Styles ----------------------------*/

.text__wr {
    a {
        color: #00aca7 !important;
        text-decoration: none !important;
    }

    ul {
        li {
            margin: inherit !important;
        }
    }

    p {
        margin: inherit !important;
    }

    span {
        font-size: inherit !important;
        margin: inherit !important;
        font-family: inherit !important;
    }
}
