#popup-ad {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 400px;
  z-index: 99;
  transform: translateX(calc(100% + 20px));

  transition: ease-in-out 0.3s;

  &.active {
    transform: translateX(0);
  }
}

.popup-advert {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.popup-ad {
  &__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #fff;
    z-index: 4;
    cursor: pointer;
    transition: ease-in-out 0.3s;

    &:hover {
      border-radius: 10px;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    padding: 20px;
  }

  &__title {
    color: #fff;
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &__sub-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }

  &__text {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    @include max-line-leng(4);

    &.bold {
      font-weight: 700;
    }

    &.location {
      margin-bottom: 20px;
    }
  }

  &__btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    margin-top: 20px;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    border: solid 2px #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(50% - 8px);
    height: 42px;

    transition: ease-in-out 0.3s;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:hover {
      background: #fff;
      border-radius: 8px;
      color: #707070;
    }
  }

  &__blog {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
  }

  &__job {
    background: #f2b825;

    .icons-wrap {
      display: flex;
      align-items: center;
      -webkit-mask-image: -webkit-gradient(linear,90% 100%,100% bottom,from(#000),to(transparent));
      overflow: hidden;

      margin-bottom: 20px;

      img {
        width: 52px;
        height: 52px;
        object-fit: contain;
        object-position: center;
        margin-right: 10px;
      }
    }

    &__btn-wrap {
      margin-top: auto;
    }
  }
}