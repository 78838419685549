:root {
    --popup-main: #707070;
    --popup-blue: #64c2c8;
    --popup-black: var(--tealish);
    --popup-white: #ffffff;
    --popup-dark-blue: #0e1127;
    --popup-dark-gray: #343434;
    --popup-light-gray: #dce1e4;

    --popup-font-main: "Bierstadt", sans-serif;
}

#popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 8999;
    display: none;

    &.active {
        display: block;
    }
}

.popup {
    &__close-bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: -1;
        transition: all 0.5s ease;
        animation-duration: 0.3s;
        animation-name: opacityPopupBg;
    }

    &__block {
        max-height: 80vh;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 95%;
        max-width: 1091px;
        padding: 30px ac(90px, 15px, 768, 1440) ac(30px, 15px, 768, 1440) ac(30px, 15px, 768, 1440);
        transition: all 300ms ease-out;
        animation-duration: 0.6s;

        &.recann-popup {
            padding: ac(40px, 25px);
            position: relative;

            input {
                padding: 16px 20px !important;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    transition: background-color 50000s ease-in-out 0s;
                    -webkit-text-fill-color: inherit !important;
                }
            }

            &:before, &:after {
                content: '';
                position: absolute;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:before {
                background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' width= '39' height= '44' fill= 'none' %3E%3Cpath fill= '%23C7FD89' d= 'm39 12.515-23.7 9.447L39 31.405V44L0 27.247V16.724L39 0v12.518-.003Z' /%3E%3C/svg%3E);
                left: -10px;
                top: -20px;
                width: 40px;
                height: 40px;
            }

            &:after {
                background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' width= '28' height= '86' fill= 'none' %3E%3Cpath fill= '%230CCFAA' d= 'M0 85.986v-7.8h1.994c2.169 0 3.633-.485 4.408-1.448.77-.967 1.154-2.815 1.154-5.55s-.07-5.365-.21-7.9c-.14-2.53-.21-5.33-.21-8.399 0-3.802.63-6.55 1.89-8.247 1.258-1.7 3.212-2.849 5.871-3.449v-.4c-2.659-.6-4.613-1.749-5.872-3.45-1.26-1.7-1.889-4.45-1.889-8.247 0-3.063.07-5.864.21-8.399.14-2.535.21-5.165.21-7.9 0-2.734-.385-4.582-1.154-5.55C5.632 8.28 4.162 7.8 1.994 7.8H0V0h6.606c4.613 0 8.006 1.015 10.175 3.05 2.169 2.033 3.253 5.75 3.253 11.148 0 1.8-.05 3.401-.155 4.797-.105 1.4-.23 2.75-.37 4.05-.14 1.3-.264 2.601-.37 3.902a54.39 54.39 0 0 0-.16 4.35c0 .867.16 1.734.476 2.6a6.38 6.38 0 0 0 1.519 2.35c.7.7 1.624 1.267 2.778 1.7 1.155.434 2.569.686 4.248.753v8.6c-1.68.067-3.093.319-4.248.753-1.154.433-2.079 1-2.778 1.7-.7.7-1.205 1.487-1.52 2.35a7.58 7.58 0 0 0-.474 2.6c0 1.601.055 3.05.16 4.35.105 1.3.225 2.602.37 3.902.14 1.3.264 2.65.37 4.05.104 1.4.154 3.001.154 4.797 0 5.398-1.084 9.114-3.253 11.149C14.612 84.985 11.224 86 6.606 86H0v-.014Z' /%3E%3C/svg%3E);
                left: 20px;
                bottom: -20px;
                width: 28px;
                height: 86px;
            }

            .popup__title {
                max-width: calc(100% - 45px);
            }

            a {
                color: var(--cyan);
                text-decoration: underline;

                &:hover {
                    color: var(--caribbean);
                }
            }

            .pc-inner {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }

            .pc-field {
                label {
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }

            .cv_file_name {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                overflow: hidden;
                margin: 0;
                max-width: 200px;
            }

            .custom-file-upload {
                border: 1px solid var(--tealish);
                border-radius: 25.5px;
                padding: 16px 20px;
                width: 100%;
                display: block;
                position: relative;

                input[type="file"] {
                    font-size: 0;
                    opacity: 0;
                    position: absolute;
                    cursor: pointer;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    padding: 0;
                }
            }

            .thanks {
                text-align: center;
                font-size: 28px;
                font-weight: 600;
                padding: 20px 0 40px;
            }

            @mixin media 551 {
                .pc-inner {
                    grid-template-columns: 1fr;
                }
            }

            @mixin media 768 {
                &:before, &:after {
                    display: none;
                }
            }
        }

        form {
            margin-top: 0 !important;
        }

        .simplebar-track.simplebar-vertical {
            background-color: rgba(27, 118, 185, .2);
            width: 6px;
        }

        .simplebar-scrollbar::before {
            border-radius: 0;
            width: 6px;
            background-color: var(--cyan);
            opacity: 1 !important;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        --popup-default-translate-x-from: -50%;
        --popup-default-translate-x-to: -50%;

        --popup-default-translate-y-from: -50%;
        --popup-default-translate-y-to: -50%;

        --popup-opacity-from: 0;
        --popup-opacity-to: 1;

        background: var(--popup-white);

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateY(var(--popup-default-translate-y-to)) translateX(var(--popup-default-translate-x-to));
        transition: all 300ms ease-out;
        animation-duration: 0.6s;

        &.without-opacity {
            --popup-opacity-from: 1;
        }

        &.pos-l,
        &.pos-r {
            --popup-default-translate-x-from: 0;
            --popup-default-translate-x-to: 0;
        }

        &.pos-t,
        &.pos-b {
            --popup-default-translate-y-from: 0;
            --popup-default-translate-y-to: 0;
        }

        &.pos-t {
            top: 0;
            bottom: initial;
        }

        &.pos-r {
            right: 0;
            left: initial;
        }

        &.pos-b {
            top: initial;
            bottom: 0;
        }

        &.pos-l {
            right: initial;
            left: 0;
        }

        &.full-height {
            max-height: 100vh;
            height: 100%;
            right: 0;
            left: initial;
            --popup-default-translate-x-from: 0;
            --popup-default-translate-x-to: 0;

            .popup__container {
                max-height: 90vh;
            }
        }

        &.slide-l {
            --popup-default-translate-x-from: 100%;
        }

        &.slide-r {
            --popup-default-translate-x-from: -100%;
        }

        &.slide-t {
            --popup-default-translate-y-from: -100%;
        }

        &.slide-b {
            --popup-default-translate-y-from: 100%;
        }

        &.slide-t,
        &.slide-r,
        &.slide-b,
        &.slide-l {
            animation-name: popupSlide;
        }

        @media only screen and (max-width: 1025px) {
            min-height: auto;
        }

        @media only screen and (max-width: 768px) {
            overflow-y: auto;
            &[data-simplebar] {
                overflow-y: revert;
            }
        }
        @media only screen and (max-width: 550px) {
            padding: 45px 20px 20px;
        }
    }

    &__title {
        color: var(--popup-dark-blue);
        font-size: 24px;
        font-family: var(--popup-font-main);
        text-transform: uppercase;
        font-weight: 700;

        margin-bottom: min(max(30px, 3.47vw), 50px);

        @media only screen and (max-width: 1025px) {
            font-size: 20px;
        }

        @media only screen and (max-width: 550px) {
            font-size: 18px;
        }
    }

    &__btn-close {
        position: absolute;
        right: min(max(15px, 1.74vw), 25px);
        top: min(max(15px, 1.74vw), 25px);
        transition: all 300ms ease-out;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .cross-clip {
            width: 24px;
            height: 24px;
            background: var(--popup-dark-blue);
            clip-path: polygon(
                    20% 0%,
                    0% 20%,
                    30% 50%,
                    0% 80%,
                    20% 100%,
                    50% 70%,
                    80% 100%,
                    100% 80%,
                    70% 50%,
                    100% 20%,
                    80% 0%,
                    50% 30%
            );
        }

        &:hover .cross-clip {
            background: var(--popup-black);
            transform: scale(1.05);
        }
    }
}

.old-popup-styles {
    .title-popup {
        margin-bottom: 20px;

        font-size: 50px;
        line-height: 130%;
        text-transform: uppercase;
        color: var(--popup-blue);
    }

    .pf-flex {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: 720px) {
            display: block;
        }

        .pf-column {
            width: 332px;
            margin-left: 38px;

            @media only screen and (max-width: 720px) {
                margin-left: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .pf-row {
        margin-bottom: 18px;

        .pf-label {
            display: block;
            margin-bottom: 10px;

            font-size: 24px;
            line-height: 130%;
            color: var(--popup-dark-blue);
            font-weight: normal;
        }

        .pf-text-field {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 48px;
            padding: 0 17px;
            background: var(--popup-white);
            border: 1px solid #8b8b8b;

            font-size: 20px;
            line-height: 130%;
            color: var(--popup-dark-blue);

            &::-moz-placeholder {
                opacity: 1;
                color: #d3d3d3;
            }

            &::-webkit-input-placeholder {
                opacity: 1;
                color: #d3d3d3;
            }

            &:focus::-moz-placeholder {
                opacity: 0;
            }

            &:focus::-webkit-input-placeholder {
                opacity: 0;
            }
        }
    }

    .checkBox {
        display: block;
        margin: 18px 0 42px 0;
        position: relative;

        &.col-span-full {
            grid-column: 1 / -1;
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }

        input:checked + .check-title:before {
            background: var(--cyan);
        }

        .check-title {
            position: relative;
            padding-left: 28px;

            font-size: 16px;
            line-height: 130%;
            color: var(--popup-dark-blue);
            cursor: pointer;
            transition: 0.3s;

            @mixin tab {
                font-size: 14px;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 1px;
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                border: 1px solid var(--cyan);
                background: var(--popup-white);
                transition: all 300ms ease-out;
            }

            a {
                color: inherit;
                font: inherit;
                text-decoration: underline;

                &:hover {
                    color: var(--cyan);
                }
            }

            &:hover {
                color: var(--cyan);
            }
        }
    }

    .popup_btn,
    a.popup_btn,
    button.popup_btn {
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
        text-transform: uppercase;
        display: block;
        background: none;
        color: var(--popup-blue);
        border: var(--popup-blue) 1px solid;
        padding: 15px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
            background: var(--popup-blue);
            color: var(--popup-white);
        }
    }

    .file_img_block {
        width: 100%;
        max-width: 502px;
        margin-bottom: 40px;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        input {
            position: absolute;
            left: -150px;
            top: 0;
            width: calc(100% + 150px);
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }

        .file_img_btn {
            background: #f0eef0;
            border-radius: 4px;
            padding: 0 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            color: #272727;
            font-size: 15px;
            cursor: pointer;

            span {
                margin-right: 12px;
                cursor: pointer;
            }
        }
    }

    .img_grid_block {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 22px;

        & > div {
            position: relative;

            &:hover {
                .igb-pic:before {
                    opacity: 1;
                }

                .img_del {
                    opacity: 1;
                }
            }

            .igb-pic {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                padding-top: 70%;
                cursor: pointer;
                border-radius: 4px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border: 2px solid #fe6b00;
                    border-radius: 4px;
                    transition: all 300ms linear;
                    box-sizing: border-box;
                    opacity: 0;
                }

                img {
                    width: 100%;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }

            .img_del {
                position: absolute;
                top: -5px;
                right: -5px;
                font-size: 16px;
                color: #272727;
                line-height: 1;
                cursor: pointer;
                transition: all 300ms linear;
                background-color: white;
                border-radius: 50%;
                opacity: 0;
                z-index: 2;
            }
        }
    }

    .pc {
        &-inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 40px;
            grid-row-gap: 20px;
            width: 100%;
        }

        &-field {
            display: flex;
            flex-direction: column;

            .pc-label {
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                border: 1px solid var(--popup-light-gray);
                border-radius: 5px;
                padding: 16px;
                font-size: 15px;
                caret-color: var(--popup-dark-blue);
                color: var(--popup-dark-blue);

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px var(--popup-white) inset !important;
                    -webkit-text-fill-color: var(--popup-dark-blue);
                }
            }
        }

        &-cv-field {
            display: flex;
            flex-direction: column;
            grid-column: 1 / span 2;

            input[type="file"] {
                display: none;
            }

            .cv_file_name {
                color: var(--popup-blue);
            }
        }

        &-label {
            font: 16px/130% var(--popup-font-main), sans-serif;
            color: var(--popup-dark-blue);
            display: inline-block;

            &.pc-cv-name {
                margin-bottom: 10px;
            }
        }

        &-btn {
            font: 16px / 130% var(--popup-font-main), sans-serif;
            background-color: var(--popup-dark-blue);
            color: var(--popup-white);
            border: none;
            outline: none;
            border-radius: 5px;
            padding: 16px;
            cursor: pointer;
            transition: 0.3s ease-in-out all;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transform: skew(45deg);
                pointer-events: none;
            }

            &:hover {
                &::after {
                    background-image: linear-gradient(
                            to right,
                            rgba(255, 255, 255, 0) 0%,
                            rgba(255, 255, 255, 0) 40%,
                            rgba(255, 255, 255, 0.7) 100%
                    );
                    animation: gradientPopupBtn 0.3s linear forwards;
                }
            }
        }

        &-captcha {
            grid-column: 1 / span 2;
        }
    }

    .custom-file-upload {
        border: 1px dashed var(--popup-light-gray);
        border-radius: 5px;
        padding: 19px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: relative;
        transition: 0.3s ease-in-out all;

        span {
            transition: 0.3s ease-in-out all;
        }

        &:hover {
            border: 1px solid var(--cyan);
            background-color: var(--cyan);

            span {
                color: var(--popup-white);
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        .pc {
            &-inner {
                grid-gap: 20px;
            }

            &-field {
                input {
                    padding: 12px;
                }
            }

            &-btn {
                padding: 12px;
            }
        }

        .custom-file-upload {
            padding: 15px;
        }
    }

    @media only screen and (max-width: 720px) {
        .pc {
            &-captcha {
                transform: scale(0.8);
                transform-origin: 0;

                & > div {
                    width: 100%;
                    height: 110px;
                }

                .g-recaptcha {
                    width: 100%;
                    height: 110px;

                    & > div {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                iframe {
                    width: 304px;
                    height: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 550px) {
        .pc {
            &-inner {
                grid-template-columns: 1fr;
                grid-gap: 20px;
            }

            &-field {
                input {
                    padding: 11px;
                }

                .pc-label {
                    margin-bottom: 7px;
                }
            }

            &-label {
                font-size: 15px;
            }

            &-cv-field {
                grid-column: 1;
                margin-top: 10px;
            }

            &-btn {
                padding: 15px;
                margin-top: 20px;
            }

            &-captcha {
                grid-column: 1;
                transform-origin: center;

                .g-recaptcha {
                    & > div {
                        & > div {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }

        .custom-file-upload {
            padding: 19px;
        }
    }

    @media only screen and (max-width: 420px) {
        .img_grid_block {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 15px;
        }
    }

    @media only screen and (max-width: 360px) {
        .pc {
            &-captcha {
                transform: scale(0.7);
                overflow: hidden;

                & > div {
                    height: 110px;
                }

                .g-recaptcha {
                    height: 110px;
                }

                iframe {
                    width: 300px;
                }
            }
        }
    }
}

.team-popup {
    &__wrapper {
        position: relative;
        display: flex;

        &::after, &::before {
            content: '';
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &::after {
            right: ac(200px, 100px, 768, 1440);
            top: -20px;
            width: ac(52px, 38px);
            height: ac(98px, 60px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='98' fill='none'%3E%3Cpath fill='%231B76B9' d='M52 97.992H0V55.648C0 41.375 3.068 30.12 9.204 21.865 15.34 13.611 25.862 6.325 40.762 0L52 20.597c-9.163 4.167-15.502 8.31-19.01 12.437-3.507 4.128-5.46 9.003-5.859 14.635h24.86V98l.009-.008Z'/%3E%3C/svg%3E");
        }

        &::before {
            right: ac(-75px, 15px, 768, 1440);
            bottom: ac(-15px, -5px, 768, 1440);
            width: ac(130px, 60px);
            height: ac(130px, 60px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='none'%3E%3Cpath fill='%230CCFAA' d='M130 47.642v34.716H82.358V130H47.642V82.358H0V47.642h47.642V0h34.716v47.642H130Z'/%3E%3C/svg%3E");
        }
    }

    &__img {
        position: relative;
        flex: 0 0 auto;
        width: 100%;
        max-width: ac(412px, 280px, 768, 1440);
        height: ac(506px, 360px, 768, 1440);
        margin-right: ac(67px, 40px, 768, 1440);
        margin-bottom: ac(-67px, -40px, 768, 1440);

        &::after, &::before {
            content: '';
            position: absolute;
            height: ac(86px, 40px);
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::after {
            left: 10px;
            top: -20px;
            background-position: left top;
            width: 39px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='44' fill='none'%3E%3Cpath fill='%23C7FD89' d='m39 12.515-23.7 9.447L39 31.405V44L0 27.247V16.724L39 0v12.518-.003Z'/%3E%3C/svg%3E");
        }

        &::before {
            right: 10px;
            bottom: -20px;
            background-position: right bottom;
            width: 28px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='86' fill='none'%3E%3Cpath fill='%230CCFAA' d='M0 85.986v-7.8h1.994c2.169 0 3.633-.485 4.408-1.448.77-.967 1.154-2.815 1.154-5.55s-.07-5.365-.21-7.9c-.14-2.53-.21-5.33-.21-8.399 0-3.802.63-6.55 1.89-8.247 1.258-1.7 3.212-2.849 5.871-3.449v-.4c-2.659-.6-4.613-1.749-5.872-3.45-1.26-1.7-1.889-4.45-1.889-8.247 0-3.063.07-5.864.21-8.399.14-2.535.21-5.165.21-7.9 0-2.734-.385-4.582-1.154-5.55C5.632 8.28 4.162 7.8 1.994 7.8H0V0h6.606c4.613 0 8.006 1.015 10.175 3.05 2.169 2.033 3.253 5.75 3.253 11.148 0 1.8-.05 3.401-.155 4.797-.105 1.4-.23 2.75-.37 4.05-.14 1.3-.264 2.601-.37 3.902a54.39 54.39 0 0 0-.16 4.35c0 .867.16 1.734.476 2.6a6.38 6.38 0 0 0 1.519 2.35c.7.7 1.624 1.267 2.778 1.7 1.155.434 2.569.686 4.248.753v8.6c-1.68.067-3.093.319-4.248.753-1.154.433-2.079 1-2.778 1.7-.7.7-1.205 1.487-1.52 2.35a7.58 7.58 0 0 0-.474 2.6c0 1.601.055 3.05.16 4.35.105 1.3.225 2.602.37 3.902.14 1.3.264 2.65.37 4.05.104 1.4.154 3.001.154 4.797 0 5.398-1.084 9.114-3.253 11.149C14.612 84.985 11.224 86 6.606 86H0v-.014Z'/%3E%3C/svg%3E");
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    &__title-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: ac(-19px, -16px);
            width: ac(7px, 6px);
            height: 100%;
            background-color: var(--yellow-green);
        }
    }

    &__name {
        margin-bottom: 10px;
        font-size: ac(34px, 24px);
        line-height: ac(44px, 34px);
        font-family: var(--font-sec);
        font-weight: 700;
    }

    &__descr {
        padding-right: 15px;
        overflow-y: auto;
        max-height: 120px;
        line-height: 24px;
    }

    &__social-box {
        margin-top: 20px;
        display: flex;
    }

    &__soc {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background-color: var(--cyan);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: .3s ease;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &:hover {
            background-color: var(--caribbean);
        }

        &--in {
            .team-popup__icon {
                width: 17px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' fill='none'%3E%3Cpath fill='%23fff' d='M3.786 2.167a1.667 1.667 0 1 1-3.333-.001 1.667 1.667 0 0 1 3.333.002Zm.05 2.9H.503v10.434h3.333V5.067Zm5.267 0H5.786v10.434H9.07v-5.475c0-3.05 3.975-3.333 3.975 0V15.5h3.292V8.893c0-5.142-5.884-4.95-7.267-2.426l.033-1.4Z'/%3E%3C/svg%3E");
            }
        }

        &--tw {
            .team-popup__icon {
                width: 18px;
                height: 14px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' fill='none'%3E%3Cpath fill='%23fff' d='M17.468 1.713a6.987 6.987 0 0 1-2.001.549A3.497 3.497 0 0 0 17 .333a6.965 6.965 0 0 1-2.213.846 3.485 3.485 0 0 0-5.939 3.178A9.895 9.895 0 0 1 1.665.716a3.485 3.485 0 0 0 1.078 4.653 3.473 3.473 0 0 1-1.578-.436v.044A3.487 3.487 0 0 0 3.96 8.394c-.514.14-1.052.16-1.575.06a3.488 3.488 0 0 0 3.256 2.421 6.993 6.993 0 0 1-5.16 1.443 9.857 9.857 0 0 0 5.342 1.567c6.41 0 9.917-5.31 9.917-9.917 0-.15-.004-.301-.011-.45a7.08 7.08 0 0 0 1.74-1.804h-.002Z'/%3E%3C/svg%3E");
            }
        }

        &--fb {
            .team-popup__icon {
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%23fff' d='M10.835 15.5V9.695h1.944l.291-2.257h-2.235v-1.44c0-.653.182-1.099 1.118-1.099h1.195V2.881a15.99 15.99 0 0 0-1.741-.09c-1.723 0-2.902 1.052-2.902 2.984v1.663H6.557v2.257h1.948V15.5H1.333a.833.833 0 0 1-.833-.833V1.333A.833.833 0 0 1 1.333.5h13.334a.833.833 0 0 1 .833.833v13.334a.833.833 0 0 1-.833.833h-3.832Z'/%3E%3C/svg%3E");
            }
        }

        &--inst {
            .team-popup__icon {
                width: 18px;
                height: 18px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23fff' d='M9 .667c2.264 0 2.546.008 3.435.05.887.041 1.492.18 2.023.387.55.212 1.014.498 1.477.961.424.417.751.92.96 1.477.207.53.346 1.136.388 2.023.04.888.05 1.17.05 3.435 0 2.264-.008 2.547-.05 3.435-.042.887-.181 1.492-.387 2.023a4.07 4.07 0 0 1-.961 1.477c-.417.424-.92.751-1.477.96-.53.207-1.136.347-2.023.388-.889.04-1.171.05-3.435.05-2.264 0-2.547-.008-3.435-.05-.888-.041-1.492-.181-2.024-.387a4.074 4.074 0 0 1-1.476-.961 4.086 4.086 0 0 1-.961-1.477c-.207-.53-.346-1.136-.387-2.023-.04-.888-.05-1.17-.05-3.435 0-2.264.008-2.547.05-3.435.041-.888.18-1.492.387-2.023.209-.557.537-1.06.96-1.477a4.08 4.08 0 0 1 1.478-.96C4.072.896 4.676.757 5.565.716 6.453.677 6.735.667 9 .667Zm0 4.166a4.167 4.167 0 1 0 0 8.334 4.167 4.167 0 0 0 0-8.334Zm5.416-.208a1.041 1.041 0 1 0-2.082 0 1.041 1.041 0 0 0 2.082 0ZM9 6.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z'/%3E%3C/svg%3E");
            }
        }
    }

    @media (max-width: 768px) {
        &__wrapper {
            flex-direction: column;

            &::after {
                right: ac(50px, -12px, 320, 768);
                top: ac(77px, 30px, 320, 768);
                width: ac(152px, 46px, 320, 768);
                height: ac(160px, 85px, 320, 768);
            }

            &::before {
                right: 0;
                bottom: -5px;
                width: 50px;
                height: 50px;
            }
        }

        &__title-box {
            margin-left: 16px;
        }

        &__img {
            margin: 0 auto ac(40px, 20px);
            max-width: 400px;
            height: ac(360px, 280px, 320, 768);
        }

        &__descr {
            width: calc(100% + 10px);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .popup-center {
        width: 95%;
        min-height: auto;
        height: 90%;
        max-height: none;
        padding: 45px;

        .title-popup {
            margin-bottom: 30px;
        }

        .checkBox {
            margin: 0;
        }
    }

    .pc {
        &-inner {
            grid-gap: 20px;
        }

        &-field {
            input {
                padding: 12px;
            }
        }

        &-btn {
            padding: 12px;
        }
    }

    .custom-file-upload {
        padding: 15px;
    }
}

@media only screen and (max-width: 720px) {
    .pc {
        &-captcha {
            transform: scale(0.8);
            transform-origin: 0;

            & > div {
                width: 100%;
                height: 110px;
            }

            .g-recaptcha {
                width: 100%;
                height: 110px;

                & > div {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            iframe {
                width: 304px;
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .popup-center {
        width: 95%;
        height: 80%;
        padding: 20px 15px;

        .title-popup {
            margin-bottom: 40px;
            font-size: 18px;
        }

        .checkBox {
            margin: 0;
            grid-column: 1;

            .check-title {
                font-size: 15px;
            }
        }

        .close-popup {
            font-size: 20px;
            top: 20px;
            right: 15px;
        }
    }

    .pc {
        &-inner {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

        &-field {
            input {
                padding: 11px;
            }

            .pc-label {
                margin-bottom: 7px;
            }
        }

        &-label {
            font-size: 15px;
        }

        &-cv-field {
            grid-column: 1;
            margin-top: 10px;
        }

        &-btn {
            padding: 15px;
            margin-top: 20px;
        }

        &-captcha {
            grid-column: 1;
            transform-origin: center;

            .g-recaptcha {
                & > div {
                    & > div {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .custom-file-upload {
        padding: 19px;
    }
}

@media only screen and (max-width: 420px) {
    .popup {
        &.popup_height_full {
            padding: 40px 20px;
        }
    }

    .img_grid_block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
    }
}

@media only screen and (max-width: 360px) {
    .pc {
        &-captcha {
            transform: scale(0.7);
            overflow: hidden;

            & > div {
                height: 110px;
            }

            .g-recaptcha {
                height: 110px;
            }

            iframe {
                width: 300px;
            }
        }
    }
}

@keyframes popupSlide {
    from {
        transform: translateY(var(--popup-default-translate-y-from)) translateX(var(--popup-default-translate-x-from));
        opacity: var(--popup-opacity-from);
    }

    to {
        transform: translateY(var(--popup-default-translate-y-to)) translateX(var(--popup-default-translate-x-to));
        opacity: var(--popup-opacity-to);
    }
}

@keyframes opacityPopupBg {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes gradientPopupBtn {
    0% {
        transform: translateX(-100%) skew(45deg);
    }

    100% {
        transform: translateX(100%) skew(45deg);
    }
}
