@import "./_breakpoints.pcss";

@define-mixin media $width {
    @media only screen and (max-width: $(width)px) {
        @mixin-content;
    }
}

@define-mixin min-media $width {
    @media only screen and (min-width: $(width)px) {
        @mixin-content;
    }
}

@define-mixin transition-all $time: 0.25s {
    transition: ease-in-out $time;
}

@define-mixin aspect-ratio $width, $height, $maxHeight: 9999px, $minHeight: 0px {
    position: relative;
    overflow: hidden;

    /* fix for hover scale on image on Safari 15.1 */
    mask-image: radial-gradient(white, black);

    &::before {
        content: "";
        display: block;
        padding-bottom: max(min(perc($height, $width), $maxHeight), $minHeight);
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
    }
}

@define-mixin max-line-length-one {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@define-mixin max-line-length $number {
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: $number;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@define-mixin transparent-bg-input $textColor: #fff {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: $textColor !important;
    }
}

@define-mixin scrollbar $thumb-color, $bg-color {
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $bg-color;
        border-radius: 5px;
        transition: background 0.2s ease-in-out;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $thumb-color;
    }
}
